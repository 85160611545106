body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: white !important;
}


.swiper-pagination-bullet {
    background: white !important;
    border: 1px solid white !important;
    width: 9px !important;
    height: 9px !important;
    opacity: 0.35 !important;
    transition: opacity 0.3s cubic-bezier(.35,0,.25,1);
}

.swiper-pagination-bullet-active {
    background: white !important;
    opacity: 1 !important;
}